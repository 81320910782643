<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form id="iiotLdapSynchronizationUsersForm" ref="synchronizationUsersForm">
    <v-row>
      <v-col cols="12">
        <h3 class="mb-5">{{ $t('ldap.ldapSynchronization.users') }}:</h3>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-text-field
              id="iiotLdapSynchronizationUsersSearchBase"
              v-model="ldapData.users.searchBase"
              :disabled="!isConnectionCorrect"
              :rules="[rules.required, rules.no_control_characters]"
              :label="$t('ldap.ldapSynchronization.searchBase')"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ ldapData.users.searchBase }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-text-field
              id="iiotLdapSynchronizationUsersFilter"
              v-model="ldapData.users.filter"
              :disabled="!isConnectionCorrect"
              :rules="[rules.required, rules.no_control_characters]"
              :label="$t('ldap.ldapSynchronization.filter')"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ ldapData.users.filter }}</span>
        </v-tooltip>
        <v-text-field
          id="iiotLdapSynchronizationUserFirstName"
          v-model="ldapData.users.firstName"
          :disabled="!isConnectionCorrect"
          :rules="[rules.required, rules.no_control_characters]"
          :label="$t('ldap.ldapSynchronization.firstName')"
        />
        <v-text-field
          id="iiotLdapSynchronizationUserLastName"
          v-model="ldapData.users.lastName"
          :disabled="!isConnectionCorrect"
          :rules="[rules.required, rules.no_control_characters]"
          :label="$t('ldap.ldapSynchronization.lastName')"
        />
        <v-text-field
          id="iiotLdapSynchronizationUserEmail"
          v-model="ldapData.users.email"
          :disabled="!isConnectionCorrect"
          :rules="[rules.required, rules.no_control_characters]"
          :label="$t('ldap.ldapSynchronization.email')"
        />
        <v-text-field
          id="iiotLdapSynchronizationUserUsername"
          v-model="ldapData.users.username"
          :disabled="!isConnectionCorrect"
          :rules="[rules.required, rules.no_control_characters]"
          :label="$t('ldap.ldapSynchronization.username')"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants';

export default {
  data: () => ({
    VALIDATION_REGEX,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('ldap.ldapSynchronization.required'),
        no_control_characters: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter'),
      };
    },
    ldapData() {
      return this.$store.getters['ldap/getDefaultLdap'];
    },
    isConnectionCorrect() {
      return this.$store.getters['ldap/getStatusOfTestConnection'];
    },
  },
};
</script>
