<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-card outlined>
    <v-list-item>
      <v-list-item-content>
        <h3>
          {{ $t('ldap.ldapConnection.title') }}
        </h3>
        <v-list-item-subtitle class="mt-3">
          <v-form id="iiotLdapConnectionForm" ref="connectionForm" @submit.prevent="testConnection">
            <v-row no-gutters>
              <v-col cols="12" lg="3">
                <v-col>
                  <v-text-field
                    id="iiotLdapConnectionName"
                    v-model="ldapData.name"
                    :rules="[rules.required, rules.no_control_characters]"
                    :label="$t('ldap.ldapConnection.name')"
                    autofocus
                  />
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-col cols="8" lg="9">
                      <v-text-field
                        id="iiotLdapConnectionUrl"
                        v-model="ldapData.url"
                        :rules="[rules.required, rules.no_control_characters]"
                        :label="$t('ldap.ldapConnection.url')"
                      />
                    </v-col>
                    <v-row justify="center" class="pt-8">
                      <span>:</span>
                    </v-row>
                    <v-col cols="3" lg="2">
                      <v-text-field
                        id="iiotLdapConnectionPort"
                        v-model="ldapData.port"
                        :rules="[rules.portRequired, rules.port_range]"
                        :label="$t('ldap.ldapConnection.port')"
                        :min="0"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
              <v-col cols="12" lg="3">
                <v-col>
                  <v-text-field
                    id="iiotLdapConnectionBindDN"
                    v-model="ldapData.bindDN"
                    :rules="[rules.required, rules.no_control_characters]"
                    :label="$t('ldap.ldapConnection.bindDN')"
                  />
                </v-col>
                <v-col>
                  <password-field
                    id="iiotLdapConnectionPassword"
                    v-model="ldapData.password"
                    :label="$t('ldap.ldapConnection.password')"
                  />
                </v-col>
                <v-col>
                  <v-switch
                    id="iiotLdapConnectionSecureOption"
                    v-model="ldapData.tls"
                    :label="$t('ldap.ldapConnection.secureConnectionOption')"
                    @change="changeSecureConnectionOption()"
                  />
                </v-col>
              </v-col>
            </v-row>
            <v-row class="mb-0">
              <nerve-button
                id="iiotLdapConnectionTestButton"
                :text="$t('ldap.ldapConnection.testConnection')"
                type-of-btn="action"
                size="small"
                :loading="testConnectionResponse"
                class="mt-0 ml-5 mb-0"
                @click-event="testConnection()"
              />
              <div class="ml-3 d-flex align-center">
                <v-img v-if="isConnectionCorrect" id="iiotLdapConnectionStatusOk" src="img/online.svg" />
                <v-img v-if="isConnectionCorrect === false" id="iiotLdapConnectionStatusNotOk" src="img/offline.svg" />
              </div>
            </v-row>
          </v-form>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
/* eslint-disable func-names */
import { NerveButton } from 'nerve-ui-components';
import PasswordField from '@/components/shared/PasswordField.vue';
import { REPOSITORY_PASSWORD, VALIDATION_REGEX } from '@/constants';
import { SSL, TLS } from '@/model/local-repository/ldap/ldap.model';

export default {
  components: {
    PasswordField,
    NerveButton,
  },
  data: () => ({
    REPOSITORY_PASSWORD,
    VALIDATION_REGEX,
    formReadyCounter: 0,
    SSL,
    TLS,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('ldap.ldapConnection.required'),
        portRequired: (value) => !!value || this.$t('ldap.ldapConnection.portRequired'),
        no_control_characters: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter'),
        port_range: (value) => VALIDATION_REGEX.PORT_RANGE.test(value) || this.$t('ldap.invalid_port_range'),
      };
    },
    ldapData() {
      return this.$store.getters['ldap/getDefaultLdap'];
    },
    isConnectionCorrect() {
      return this.$store.getters['ldap/getStatusOfTestConnection'];
    },
    testConnectionResponse() {
      return this.$store.getters['ldap/testConnectionResponse'];
    },
  },
  watch: {
    'ldapData.url': function () {
      this.watchData();
    },
    'ldapData.port': function () {
      this.watchData();
    },
    'ldapData.name': function () {
      this.watchData();
    },
    'ldapData.password': function () {
      this.watchData();
    },
    'ldapData.bindDN': function () {
      this.watchData();
    },
  },
  updated() {
    try {
      this.formReadyCounter = 5;
      if (!this.$refs.connectionForm.validate()) {
        this.$store.dispatch('ldap/set_test_connection_status_without_save', null);
      }
    } catch (e) {
      this.$log.debug(e);
    }
  },
  methods: {
    async testConnection() {
      try {
        if (this.$refs.connectionForm.validate()) {
          await this.$store.dispatch('ldap/test_connection', this.$store.getters['ldap/getDefaultLdap']);
          this.formReadyCounter = 5;
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    changeSecureConnectionOption() {
      this.ldapData.port = this.ldapData.tls ? this.TLS : this.SSL;
    },
    watchData() {
      try {
        // eslint-disable-next-line no-plusplus
        this.formReadyCounter++;
        if (this.formReadyCounter > 5) {
          this.$store.dispatch('ldap/set_test_connection_status_without_save', null);
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
  },
};
</script>
